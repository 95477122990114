import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SideMenu, TopMenu } from "@/components";
import { useAuth } from "@/hooks/use-auth";

const MainContent = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <TopMenu />
      <SideMenu />

      <div className="flex flex-col p-4 sm:gap-4 sm:py-4 sm:pl-20 sm:pr-6">
        {children}
      </div>
    </div>
  );
};

export default MainContent;
