import axios from "axios";

import { getStorageData } from "@/utils";

const { VITE_API_URL } = import.meta.env;

const api = axios.create({
  baseURL: VITE_API_URL,
});

const getAuthToken = async () => {
  try {
    const value = await getStorageData("zvcbcve");
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return;
  }
  return null;
};

api.interceptors.request.use(async (config) => {
  const token = await getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
