import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { CommandCenter } from "@/components/command-center";
import { ThemeProvider } from "@/components/theme-provider";
import { Toaster } from "@/components/ui/sonner";
import { AuthProvider } from "@/context/auth-context";
import { ToastProvider } from "@/context/toast-context";
import Routes from "@/routes";

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastProvider>
          <ThemeProvider>
            <Routes />
            <CommandCenter />
          </ThemeProvider>
        </ToastProvider>
      </AuthProvider>

      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
