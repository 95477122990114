/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  useEffect,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";

import { Loading } from "@/components/Loading";
import { IMerchantResp } from "@/services/merchant/interfaces";
import { getMerchants } from "@/services/merchant/merchant.service";

import { labelStyle, selectStyle } from "./styles";

interface SelectProps {
  value: any | null;
  setValue?: Dispatch<SetStateAction<string>>;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options?: any | [] | undefined | null;
  size?: string;
  name?: string;
  isNotFetch?: boolean;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  required?: boolean;
}

export const CompanySelect = ({
  value,
  setValue,
  onChange,
  options,
  size,
  isNotFetch,
  name,
  disabled,
  required,
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [merchants, setMerchants] = useState<IMerchantResp | null>(null);

  const sanitazerOption = (result: any) => {
    let helper;
    if (Array.isArray(result)) helper = result;
    else helper = [result];

    const newObj = helper.map((item) => {
      return {
        ...item,
      };
    });

    return newObj;
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange ? onChange(event) : setValue ? setValue(event.target.value) : null;
  };

  const firstRender = async () => {
    setIsLoading(true);
    const response = await getMerchants({ all: true, itemsPerPage: 1000 });
    if (response) {
      setMerchants(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    !isNotFetch && firstRender();
  }, []);

  return (
    <div className={`relative flex flex-col ${size}`}>
      <label className={labelStyle}>Empresas</label>
      <select
        id="select"
        name={name}
        className={selectStyle}
        value={value}
        disabled={disabled}
        required={required}
        placeholder={"Bairros"}
        onChange={handleSelectChange}
      >
        <option key={"empty"} value={""}>
          {"Empresas"}
        </option>
        {isLoading ? (
          <option disabled value="" className="text-center">
            <Loading /> Loading...
          </option>
        ) : (
          sanitazerOption(
            options ? options?.resp?.data : merchants?.resp?.data,
          ).map((option: any, index) => (
            <option key={option?.id + index} value={option?.id} className="">
              {option?.companyName}
            </option>
          ))
        )}
      </select>
    </div>
  );
};
