import { SVGProps } from "react";

import { cn } from "@/utils";

export const HelmetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 24 24"
    className={cn("block h-5 w-5 fill-current", props.className)}
    {...props}
  >
    <path d="M12 22h2.787A7.211 7.211 0 0 0 22 14.787V12a9.953 9.953 0 0 0-.6-3.377l-.006-.023A10 10 0 1 0 12 22Zm7.737-12A7.953 7.953 0 0 1 20 12v2.787c0 .072-.01.142-.013.213h-6.129a1 1 0 0 1-.961-.725l-.857-3A1 1 0 0 1 13 10ZM12 4a8 8 0 0 1 6.918 4H13a3 3 0 0 0-2.884 3.824l.857 3A3.013 3.013 0 0 0 13.858 17H19.5a5.182 5.182 0 0 1-4.71 3H12a8 8 0 0 1 0-16Z" />
  </svg>
);
