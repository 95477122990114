import { type ClassValue, clsx } from "clsx";
import { format, formatDistanceToNow } from "date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import { twMerge } from "tailwind-merge";

import { usersCredencial } from "@/enums/user.enum";

export const unmask = (value: string) => {
  return value ? value.replace(/[^\d]/g, "") : "";
};

export function clearToken() {
  try {
    localStorage.removeItem("zvcbcve");
  } catch (e) {
    return e;
  }

  return true;
}

export function getStorageData(item: string) {
  try {
    return localStorage.getItem(item);
  } catch (e) {
    return e;
  }
}

export function setStorageData(item: string, data: string | any) {
  try {
    localStorage.setItem(item, data);
  } catch (e) {
    return e;
  }

  return true;
}

export const emailValidation = (email: string | null) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  return regex.test(email || "");
};

export const renderPermissions = (role?: number) => {
  switch (role) {
    case usersCredencial.MASTER:
      return "Administrador";

    case usersCredencial.COMPANY_MASTER:
      return "Administrador Empresa";

    case usersCredencial.DELIVERYMAN:
      return "Entregador";

    case usersCredencial.COMPANY_WORKER:
      return "Suporte";

    default:
      return "";
  }
};

export const renderCPF = (cpf?: string) => {
  const formattedCPF = cpf
    ? cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    : "-";
  return formattedCPF;
};

export const functionPermission = (permission?: boolean[]) => {
  return permission?.includes(true);
};

export const formatCellphone = (cellphone?: string) => {
  if (!cellphone) {
    return ""; // Retorna uma string vazia se o número for nulo ou indefinido
  }

  const cleanedCellphone = String(cellphone).replace(/\D/g, ""); // Remove caracteres não numéricos

  // Usa expressões regulares para formatar o número
  return cleanedCellphone.replace(/(\d{2})(\d)(\d{4})(\d{4})/, "($1) $2 $3-$4");
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function abbreviateName(name: string) {
  return name
    .split(" ")
    .slice(0, 2)
    .map((n) => n.charAt(0).toUpperCase())
    .join("");
}

export function maskPhone(phone: string) {
  if (!phone) return "";

  const cleanedPhone = unmask(phone);
  if (cleanedPhone.length === 11) {
    return cleanedPhone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }

  return cleanedPhone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
}

export function formatTime(time: string | Date) {
  try {
    return format(new Date(time), "dd/MM/yyyy HH:mm");
  } catch (error) {
    return "horário inválido";
  }
}

export function formatMoney(value: number) {
  if (!value) {
    return "R$ 0,00";
  }

  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function getTimeDistance(date: Date | string) {
  try {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale: ptLocale,
    });
  } catch (error) {
    return "";
  }
}

export function maskZipCode(zipCode: string) {
  return zipCode.replace(/(\d{5})(\d{3})/, "$1-$2");
}

export function shouldRejectKeyPress() {
  const activeElement = document.activeElement as HTMLElement;
  const inputs = ["input", "textarea", "select", "option"];

  return inputs.includes(activeElement.tagName.toLowerCase());
}
