import * as Sentry from "@sentry/react";
import { lazy, Suspense, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { MainContent } from "@/components";
import { AnimatedLoading } from "@/components/animated-loading";
import { AuthContext } from "@/context/auth-context";
import { usersCredencial } from "@/enums/user.enum";
import UserProfileView from "@/pages/user-profile";

const Deliverymans = lazy(() =>
  import("@/pages/deliverymans").then((module) => ({
    default: module.Deliverymans,
  })),
);
const StatsPage = lazy(() =>
  import("@/pages/stats").then((module) => ({ default: module.StatsPage })),
);
const Companies = lazy(() =>
  import("@/pages/companies").then((module) => ({ default: module.Companies })),
);
const MerchantView = lazy(() => import("@/pages/merchant"));
const RegisterMerchantView = lazy(
  () => import("@/pages/merchant/registerView"),
);
const MotoboyView = lazy(() => import("@/pages/motoboy"));
const RegisterDeliverymanView = lazy(
  () => import("@/pages/motoboy/registerView"),
);
const NeightborhoodView = lazy(() => import("@/pages/neightborhood"));
const UsersView = lazy(() => import("@/pages/users"));
const RegisterUsersView = lazy(() => import("@/pages/users/registerView"));
const RegisterNeightborhoodView = lazy(
  () => import("@/pages/neightborhood/registerView"),
);
const RegionView = lazy(() => import("@/pages/region"));
const AddNeightborhoodPrice = lazy(
  () => import("@/pages/region/addNeighborhoodPrice"),
);
const PlansView = lazy(() => import("@/pages/plans"));
const ClientsView = lazy(() => import("@/pages/clients"));
const RegisterClientView = lazy(() => import("@/pages/clients/registerView"));
const ReportsView = lazy(() => import("@/pages/reports"));
const LoginForm = lazy(() =>
  import("@/pages/login").then((module) => ({ default: module.LoginForm })),
);
const Delivery = lazy(() =>
  import("@/pages/deliveries").then((module) => ({ default: module.Delivery })),
);
const RegionsPage = lazy(() =>
  import("@/pages/regions").then((module) => ({ default: module.RegionsPage })),
);
const PricesPage = lazy(() =>
  import("@/pages/regions/prices").then((module) => ({
    default: module.PricesPage,
  })),
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Router = () => {
  const { user } = useContext(AuthContext);
  const isDeliveryMan = user?.UserRoles.map((item) => {
    if (Number(item?.roleID) === usersCredencial.DELIVERYMAN) {
      return true;
    }
    return false;
  });

  const functionPermission = (permission?: boolean[]) => {
    return permission?.includes(true);
  };

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <Suspense fallback={<AnimatedLoading />}>
            <LoginForm />
          </Suspense>
        }
      />

      <Route path="/dashboard">
        <Route
          index
          element={
            <MainContent>
              <Navigate
                to={
                  functionPermission(isDeliveryMan)
                    ? "/dashboard/reports"
                    : "/dashboard/deliveries"
                }
              />
            </MainContent>
          }
        />

        <Route path="users">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <UsersView />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path=":type/:id?"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegisterUsersView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="clients">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <ClientsView />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path=":type/:id?"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegisterClientView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="plans">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <PlansView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="neightborhood">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <NeightborhoodView />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path=":type/:id?"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegisterNeightborhoodView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="regions">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegionsPage />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path="prices/:id"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <PricesPage />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="region">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegionView />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path="add-neighborhood-price/:id"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <AddNeightborhoodPrice />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path=":type/:id?"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegisterNeightborhoodView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route
          path="companies"
          element={
            <MainContent>
              <Suspense fallback={<AnimatedLoading />}>
                <Companies />
              </Suspense>
            </MainContent>
          }
        />

        <Route path="merchant">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <MerchantView />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path=":type/:id?"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegisterMerchantView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="motoboy">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <MotoboyView />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path=":type/:id?"
            element={
              <MainContent>
                <Suspense fallback={<AnimatedLoading />}>
                  <RegisterDeliverymanView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route
          path="deliverymans"
          element={
            <MainContent>
              <Suspense fallback={<AnimatedLoading />}>
                <Deliverymans />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          path="reports"
          element={
            <MainContent>
              <Suspense fallback={<AnimatedLoading />}>
                <ReportsView />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          path="stats"
          element={
            <MainContent>
              <Suspense fallback={<AnimatedLoading />}>
                <StatsPage />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          path="deliveries"
          element={
            <MainContent>
              <Suspense fallback={<AnimatedLoading />}>
                <Delivery />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          index
          path="userProfile/:id?"
          element={
            <MainContent>
              <Suspense fallback={<AnimatedLoading />}>
                <UserProfileView />
              </Suspense>
            </MainContent>
          }
        />

        <Route path="*" element={<Navigate to="/dashboard/deliveries" />} />
      </Route>

      <Route path="*" element={<h1>Esta pagina não foi encontrada</h1>} />
    </SentryRoutes>
  );
};

export default Router;
