import { create } from "zustand";

export type AppStore = {
  isSheetMenuOpen: boolean;
  setIsSheetMenuOpen: (value: boolean) => void;
};

export const initialState = {
  isSheetMenuOpen: false,
};

export const useApp = create<AppStore>((set) => ({
  setIsSheetMenuOpen: (value: boolean) => set({ isSheetMenuOpen: value }),
  ...initialState,
}));
