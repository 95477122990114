import { ColorWheelIcon, MoonIcon, SunIcon } from "@radix-ui/react-icons";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import Logo from "@/assets/img/sublogoPRed.png";
import { useTheme } from "@/components/theme-provider";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { AuthContext } from "@/context/auth-context";
import { abbreviateName } from "@/utils";

import { SheetMenu } from "../MobileMenu";

export const TopMenu = () => {
  const { logout, user, getUserToken } = useContext(AuthContext);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    if (!user) {
      getUserToken();
    }
  }, [user]);

  return (
    <nav className="border-b bg-muted/40 sm:hidden">
      <div className="flex h-16 items-center justify-between px-4">
        <Link to="/dashboard">
          <img src={Logo} className="visible h-8" />
        </Link>

        <div className="flex items-center gap-4">
          <SheetMenu />

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                <Avatar>
                  <AvatarFallback>
                    {abbreviateName(user?.personName || "SN")}
                  </AvatarFallback>
                </Avatar>
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="w-56" align="end">
              <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {user?.personName}
                  </p>
                  <p className="text-xs leading-none text-muted-foreground">
                    {user?.email}
                  </p>
                </div>
              </DropdownMenuLabel>

              <DropdownMenuSeparator />

              <DropdownMenuSub>
                <DropdownMenuSubTrigger>Alterar tema</DropdownMenuSubTrigger>

                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuCheckboxItem
                      checked={theme === "dark"}
                      onClick={() => setTheme("dark")}
                    >
                      <MoonIcon className="mr-2 h-4 w-4" />
                      <span>Tema escuro</span>
                    </DropdownMenuCheckboxItem>

                    <DropdownMenuCheckboxItem
                      checked={theme === "light"}
                      onClick={() => setTheme("light")}
                    >
                      <SunIcon className="mr-2 h-4 w-4" />
                      <span>Tema claro</span>
                    </DropdownMenuCheckboxItem>

                    <DropdownMenuCheckboxItem
                      checked={theme === "system"}
                      onClick={() => setTheme("system")}
                    >
                      <ColorWheelIcon className="mr-2 h-4 w-4" />
                      <span>Sistema</span>
                    </DropdownMenuCheckboxItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>

              <Link to={`/dashboard/userProfile/${user?.id}`}>
                <DropdownMenuItem>Editar perfil</DropdownMenuItem>
              </Link>

              <DropdownMenuSeparator />

              <DropdownMenuItem onClick={() => logout()}>Sair</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </nav>
  );
};
