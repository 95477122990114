import { TooltipProvider } from "@/components/ui/tooltip";
import { UserButton } from "@/components/user-button";

import { ResumedLinks } from "../Links";

export const SideMenu = () => {
  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
      <nav className="flex flex-col items-center gap-4">
        <TooltipProvider delayDuration={0}>
          <ResumedLinks />
        </TooltipProvider>
      </nav>

      <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
        <UserButton />
      </nav>
    </aside>
  );
};
