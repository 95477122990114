import api from "@/services/api";
import { getStorageData } from "@/utils";

import {
  INeighborhoodResp,
  IShowNeighborhoodResp,
  IShowNeighborhood,
  IDeleteResp,
} from "./interfaces";

const token = getStorageData("zvcbcve") || null;
const headers = token
  ? { Authorization: `Bearer ${getStorageData("zvcbcve")}` }
  : {};

export const getNeighborhood = (query?: {
  itemsPerPage?: number;
  page?: number;
  all?: boolean;
}): Promise<INeighborhoodResp> => {
  const resp: INeighborhoodResp = {
    resp: null,
    status: null,
  };

  return api
    .get("neighborhood", { params: query, headers })
    .then((preResp) => {
      resp.resp = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.resp = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const showNeighborhood = (props: {
  id?: string;
}): Promise<IShowNeighborhoodResp> => {
  const resp: IShowNeighborhoodResp = {
    data: null,
    status: null,
  };

  return api
    .get(`neighborhood/${props?.id}`, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const createNeighborhood = (
  body: IShowNeighborhood | null,
): Promise<IShowNeighborhoodResp> => {
  const resp: IShowNeighborhoodResp = {
    data: null,
    status: null,
  };

  return api
    .post("neighborhood", body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const updateNeighborhood = (
  body: IShowNeighborhood | null,
  id?: string,
): Promise<IShowNeighborhoodResp> => {
  const resp: IShowNeighborhoodResp = {
    data: null,
    status: null,
  };

  return api
    .put(`neighborhood/${id}`, body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    })
    .finally(() => {
      return resp;
    });
};

export const deleteNeighborhood = (id?: string): Promise<IDeleteResp> => {
  const resp: IDeleteResp = { status: null };

  return api
    .delete(`neighborhood/${id}`, { headers })
    .then((preResp) => {
      resp.status = preResp.status;

      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.status = err.response.status;
      }

      return resp;
    });
};
