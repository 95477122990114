import { motion } from "framer-motion";
import { CircleIcon } from "lucide-react";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "@/assets/img/sublogoPRed.png";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AuthContext } from "@/context/auth-context";
import { usersCredencial } from "@/enums/user.enum";
import { useApp } from "@/hooks/use-app";
import { UserRoles } from "@/services/users/interfaces";

import { PAGES, type Page } from "../../const";

export function ResumedLinks() {
  const { isMaster, isCompanyMaster } = useContext(AuthContext);
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col items-center gap-2">
      <Link to="/" className="my-4 p-2">
        <img src={Logo} alt="Logo" className="h-6 w-6" />
      </Link>

      <TooltipProvider delayDuration={0}>
        {PAGES.filter((page) => {
          if (isMaster()) {
            return true;
          }

          if (page.isMasterOnly && !isMaster()) {
            return false;
          }

          if (page.isCompanyMasterOnly && !isCompanyMaster()) {
            return false;
          }

          return true;
        }).map(({ label, path, icon: Icon }) => {
          const isActive = path === pathname;

          return (
            <Tooltip key={`resumed-link-to-${label}`}>
              <TooltipTrigger asChild>
                <Link
                  to={path || ""}
                  className="group relative flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground data-[active=true]:text-orange-600 md:h-8 md:w-8 hover:border"
                  data-active={isActive}
                >
                  <Icon />

                  <span className="sr-only">{label}</span>

                  {isActive && (
                    <motion.div
                      layoutId="itemtab"
                      transition={{
                        type: "spring",
                        duration: 0.4,
                        bounce: 0,
                        delay: 0.1,
                      }}
                      className="rounded-lg border border-orange-600 bg-orange-500/20 h-full w-full absolute"
                    ></motion.div>
                  )}
                </Link>
              </TooltipTrigger>

              <TooltipContent side="right">{label}</TooltipContent>
            </Tooltip>
          );
        })}
      </TooltipProvider>
    </div>
  );
}

export function Links() {
  const { user } = useContext(AuthContext);

  return (
    <div className="mt-4 flex flex-col gap-2">
      {PAGES.map((page, index) => {
        return (
          <LinkItem
            key={`page-${index}`}
            {...page}
            path={page.path || ""}
            isMasterOnly={page.isMasterOnly}
            userRoles={user?.UserRoles}
          />
        );
      })}
    </div>
  );
}

export type LinkItemProps = {
  label: string;
  path: string;
  icon: Page["icon"];
  type: Page["type"];
  isMasterOnly: Page["isMasterOnly"];
  userRoles?: UserRoles[];
  items?: Page[];
};

export function LinkItem({
  isMasterOnly,
  type,
  items,
  ...rest
}: LinkItemProps) {
  const { user } = useContext(AuthContext);
  const userRoleIds = user?.UserRoles.map((role) => role.roleID) || [];

  if (isMasterOnly && !userRoleIds.includes(usersCredencial.MASTER)) {
    return null;
  }

  if (type === "COLLAPSIBLE") {
    return (
      <LinkItemCollapsible
        {...rest}
        isMasterOnly={isMasterOnly}
        items={items}
      />
    );
  }

  return <LinkItemDefault {...rest} isMasterOnly={isMasterOnly} />;
}

export function LinkItemDefault({
  label,
  path,
  icon: Icon,
}: Omit<LinkItemProps, "type">) {
  const { pathname } = useLocation();
  const { setIsSheetMenuOpen } = useApp();

  return (
    <Link to={path}>
      <div
        data-active={pathname === path}
        className="flex items-center gap-2 rounded-lg p-3 text-lg hover:text-muted-foreground data-[active=true]:border data-[active=true]:border-orange-600 data-[active=true]:text-orange-600"
        onClick={() => {
          setIsSheetMenuOpen(false);
        }}
      >
        <Icon />

        <span>{label}</span>
      </div>
    </Link>
  );
}

export function LinkItemCollapsible({
  label,
  icon: Icon,
  items,
}: Omit<LinkItemProps, "type">) {
  return (
    <Collapsible>
      <CollapsibleTrigger asChild>
        <Button
          className="flex w-full justify-start gap-2 text-left"
          variant="ghost"
        >
          <Icon />

          <span>{label}</span>
        </Button>
      </CollapsibleTrigger>

      <CollapsibleContent className="pl-4">
        {(items || []).map((page, index) => {
          if (page.type === "DEFAULT") {
            return (
              <LinkItemDefault
                key={`page-${index}`}
                {...page}
                path={page.path || ""}
              />
            );
          }

          return null;
        })}
      </CollapsibleContent>
    </Collapsible>
  );
}
