/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpStatusCode } from "axios";
import jwt_decode from "jwt-decode";
import { useState, useEffect, ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { FlexContainer, Select, Input, CompanySelect } from "@/components";
import { ToastContext } from "@/context/toast-context";
import { usersCredencial } from "@/enums/user.enum";
import { IUserShow } from "@/services/users/interfaces";
import { showUser, updateUser } from "@/services/users/users.service";
import { emailValidation, getStorageData } from "@/utils";

const UserProfileView = () => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastContext);
  const { type, id } = useParams();

  const [, setLoading] = useState(false);

  const [data, setData] = useState<Partial<IUserShow> | null>({
    email: "",
    personID: "",
    companyID: 0,
    personName: "",
    cellphone: "",
    addressIsApartament: false,
    isBlocked: false,
    personCpfCnpj: "",
    roleID: 0,
  });
  const [dataError, setDataError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [, setCurrentUser] = useState<Partial<IUserShow> | null>(null);
  const [, setIsUpdateUserLoadingOpen] = useState(false);

  const credentials = [
    { id: 1, label: "Master", value: usersCredencial.MASTER },
    { id: 2, label: "Entregador", value: usersCredencial.DELIVERYMAN },
    { id: 3, label: "Administrador", value: usersCredencial.COMPANY_MASTER },
    { id: 4, label: "Suporte", value: usersCredencial.COMPANY_WORKER },
  ];

  const handleSubmit = async () => {
    if (!emailValidation(data?.email as string)) {
      return setDataError("invalid email");
    }

    if (
      (data?.password &&
        data?.password?.length > 0 &&
        data?.password !== confirmPassword) ||
      (confirmPassword?.length > 0 && data?.password !== confirmPassword)
    ) {
      return setDataError("password diferent");
    }
    setIsUpdateUserLoadingOpen(true);

    //const response = type === 'edit' ? await updateUser(data, id) : await createUser(data);
    const response = await updateUser(data, id);

    setIsUpdateUserLoadingOpen(false);

    if (response.status) {
      setToast({
        isOpen: true,
        statusCode: response?.status,
        error: response?.data,
      });
    }

    if (response.status === 200 || response.status === 201) {
      navigate("/dashboard");
    }

    setDataError("");
    return;
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData((state: any) => ({ ...state, [e.target.name]: e.target.value }));
  };
  const firstRender = async () => {
    setLoading(true);

    const token = getStorageData("zvcbcve");
    const decoded: any = jwt_decode(String(token));

    if (decoded.sub !== id) {
      setToast({
        isOpen: true,
        statusCode: HttpStatusCode.Forbidden,
        error: { message: "Você não tem permissão para alterar esse usuário!" },
      });

      navigate("/dashboard");
    }

    //if (type === 'edit') {
    const showUserResponse = await showUser({ id });
    if (showUserResponse) {
      setCurrentUser({
        ...showUserResponse?.data,
        roleID: showUserResponse?.data?.UserRoles[0]?.roleID,
      });
      setData({
        ...showUserResponse?.data,
        roleID: showUserResponse?.data?.UserRoles[0]?.roleID,
      });
    }
    //}
    setLoading(false);
  };

  useEffect(() => {
    firstRender();
  }, []);

  return (
    <form className="flex h-full w-full flex-col space-y-2">
      <h1 className="pb-2 pt-4 text-2xl font-bold">{`${
        type === "add" ? "Adicionar" : "Editar"
      } Usuário`}</h1>
      <h1 className="pb-2 pt-4 font-bold">Informações Gerais</h1>

      {/*
          <Switch
              boxStyle='mt-4'
              label='Bloqueado'
              value={data?.isBlocked || false}
              onChange={(e) => {
                setData((state) => ({...state, isBlocked: e.target.checked}));
              }}
          />
          */}
      <div className="grid grid-flow-col grid-cols-2 gap-4 max-md:grid-cols-1 max-md:gap-2">
        <div className="col-span-full">
          <Input
            required
            label="Nome"
            name="personName"
            value={data?.personName}
            onChange={handleChange}
            placeholder="Nome"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 max-md:col-span-full max-md:grid-cols-1 max-md:gap-2">
          <div>
            <Input
              required
              label="Celular"
              name="cellphone"
              value={data?.cellphone}
              onChange={handleChange}
              placeholder="Celular"
            />
          </div>
          <div>
            <Input
              required
              label="CPF/CNPJ"
              name="personCpfCnpj"
              value={data?.personCpfCnpj}
              onChange={handleChange}
              placeholder="CPF/CNPJ"
            />
          </div>
        </div>
      </div>

      <div className="grid grid-flow-col grid-cols-2 gap-4 max-md:grid-cols-1 max-md:gap-2">
        <div className="col-span-full">
          <Input
            required
            label="Email"
            name="email"
            value={data?.email}
            onChange={handleChange}
            placeholder="Email"
          />
          {dataError === "invalid email" && (
            <span className="ml-4 text-red-500">Digite um email válido</span>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4 max-md:col-span-full max-md:grid-cols-1 max-md:gap-2">
          <div>
            <CompanySelect
              disabled
              value={data?.companyID}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setData((state: any) => ({
                  ...state,
                  companyID: e?.target.value,
                }));
              }}
            />
          </div>

          <div>
            <Select
              disabled
              label="Credencial"
              required
              value={data?.roleID || 0}
              options={credentials}
              optionLabelProp="label"
              optionValueProp="value"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setData((state: any) => ({
                  ...state,
                  roleID: Number(e?.target.value),
                }));
              }}
            />
          </div>
        </div>
      </div>

      <div className="max-xs:grid-cols-1 max-xs:col-span-full grid grid-flow-col grid-cols-2 gap-4">
        <div className="max-xs:col-span-full">
          <Input
            type="password"
            label="Senha"
            value={data?.password}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setData((state: any) => ({ ...state, password: e.target.value }));
            }}
            placeholder="Senha"
          />
          {dataError === "password diferent" && (
            <span className="ml-4 text-red-500">Senhas não correspondem</span>
          )}
        </div>

        <div className="max-xs:col-span-full">
          <Input
            type="password"
            label="Confirmar senha"
            value={confirmPassword}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setConfirmPassword(e.target.value);
            }}
            placeholder="Confirmar senha"
          />
        </div>
      </div>

      <FlexContainer justify="end" style="pt-4 gap-2">
        <button
          type="button"
          data-modal-target="defaultModal"
          data-modal-toggle="defaultModal"
          onClick={() => navigate(-1)}
          className="mb-3 h-12 w-28 rounded bg-gray-400 p-2 duration-300 ease-linear hover:bg-gray-500 max-sm:w-full"
        >
          Voltar
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          data-modal-target="defaultModal"
          data-modal-toggle="defaultModal"
          className="mb-3 h-12 w-28 rounded bg-gray-600 p-2 duration-300 ease-linear hover:bg-gray-700 max-sm:w-full"
        >
          Confirmar
        </button>
      </FlexContainer>
    </form>
  );
};

export default UserProfileView;
