import { ColorWheelIcon, DesktopIcon } from "@radix-ui/react-icons";
import { MoonIcon, SunIcon } from "lucide-react";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { AuthContext } from "@/context/auth-context";
import { abbreviateName } from "@/utils";

import { useTheme } from "./theme-provider";
import { Button } from "./ui/button";

export function UserButton() {
  const { user, logout } = useContext(AuthContext);
  const { theme, setTheme } = useTheme();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar>
            <AvatarFallback>
              {abbreviateName(user?.personName || "SN")}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="w-56"
        side="right"
        align="end"
        sideOffset={16}
      >
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {user?.personName}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Alterar tema</DropdownMenuSubTrigger>

          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <DropdownMenuCheckboxItem
                checked={theme === "dark"}
                onClick={() => setTheme("dark")}
              >
                <MoonIcon className="mr-2 h-4 w-4" />
                <span>Tema escuro</span>
              </DropdownMenuCheckboxItem>

              <DropdownMenuCheckboxItem
                checked={theme === "light"}
                onClick={() => setTheme("light")}
              >
                <SunIcon className="mr-2 h-4 w-4" />
                <span>Tema claro</span>
              </DropdownMenuCheckboxItem>

              <DropdownMenuCheckboxItem
                checked={theme === "system"}
                onClick={() => setTheme("system")}
              >
                <DesktopIcon className="mr-2 h-4 w-4" />
                <span>Sistema</span>
              </DropdownMenuCheckboxItem>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>

        <Link to={`/dashboard/userProfile/${user?.id}`}>
          <DropdownMenuItem>Editar perfil</DropdownMenuItem>
        </Link>

        <DropdownMenuSeparator />

        <DropdownMenuItem onClick={() => logout()}>Sair</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
