import { Trash2Icon, XIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { clearToken } from "@/utils";

export function ErrorView() {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="error-box w-[500px] rounded-lg border">
        <div className="p-4">
          <div className="my-2 flex h-8 w-8 items-center justify-center rounded-full bg-red-200">
            <XIcon className="h-4 w-4 text-red-900" />
          </div>

          <div className="error-title font-medium">
            Ocorreu um erro na Pim...
          </div>

          <div className="my-4 flex flex-col gap-2 text-sm text-muted-foreground">
            <p className="text-justify">
              Recomendamos que limpe seus dados e faça login novamente. Para
              isso clique no botão{" "}
              <strong>&quot;Limpar meus dados&quot;</strong> abaixo.
            </p>

            <p className="text-justify">
              Caso o erro persista contate a Pim Delivery.
            </p>
          </div>

          <Button
            className="mt-4 w-full"
            onClick={() => {
              clearToken();
              window.location.href = "/";
            }}
          >
            <Trash2Icon className="mr-2 h-3 w-3" /> Limpar meus dados
          </Button>
        </div>
      </div>
    </div>
  );
}
