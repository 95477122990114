import { ChangeEvent, Dispatch, SetStateAction } from "react";

import { labelStyle, selectStyle } from "./styles";

interface SelectProps {
  value: any | null;
  setValue?: Dispatch<SetStateAction<string>>;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: any | [] | undefined | null;
  size?: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  name?: string;
  optionLabelProp: string;
  optionValueProp: string;
  required?: boolean;
}

export const Select = ({
  value,
  setValue,
  onChange,
  options,
  size,
  label,
  name,
  disabled,
  optionLabelProp,
  optionValueProp,
  placeholder,
  required,
}: SelectProps) => {
  const sanitazerOption = (result: any) => {
    let helper;
    if (Array.isArray(result)) helper = result;
    else helper = [result];

    const newObj = helper.map((item) => {
      return {
        ...item,
        label: item[optionLabelProp] || "",
        value: item[optionValueProp || ""],
      };
    });

    return newObj;
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange ? onChange(event) : setValue ? setValue(event.target.value) : null;
  };

  return (
    <div className={`relative flex flex-col ${size}`}>
      <label className={labelStyle}>{label}</label>
      <select
        id="select"
        name={name}
        className={selectStyle}
        value={value}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        onChange={handleSelectChange}
      >
        <option key={"empty"}>{placeholder}</option>
        {sanitazerOption(options).map((option: any, index) => (
          <option
            key={option?.id + index}
            value={option[optionValueProp]}
            className=""
          >
            {option[optionLabelProp]}
          </option>
        ))}
      </select>
    </div>
  );
};
