/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState, ReactNode } from "react";

interface ToastContextProps {
  state: {
    isOpen: boolean;
    statusCode: number | null;
    error?: string | null | any;
  };
  setToast: (data: {
    isOpen: boolean;
    statusCode: number | null;
    error?: string | null | any;
  }) => void;
  setOpen: () => void;
}

export const ToastContext = createContext<ToastContextProps>({
  state: { isOpen: false, statusCode: null, error: null },
  setToast: () => {},
  setOpen: () => {},
});

export const ToastProvider = (props: { children: ReactNode }) => {
  const [state, setState] = useState<{
    isOpen: boolean;
    statusCode: number | null;
    error?: string | null | any;
  }>({
    isOpen: false,
    statusCode: null,
    error: null,
  });

  const setToast = (data: {
    isOpen: boolean;
    statusCode: number | null;
    error?: string | null | any;
  }) => {
    setState(data);
  };

  const setOpen = () => {
    setState((state) => ({ ...state, isOpen: !state.isOpen }));
  };

  return (
    <ToastContext.Provider value={{ state, setToast, setOpen }}>
      {props.children}
    </ToastContext.Provider>
  );
};
