import { MenuIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useApp } from "@/hooks/use-app";

import { Links } from "../Links";

export function SheetMenu() {
  const { isSheetMenuOpen, setIsSheetMenuOpen } = useApp();

  return (
    <Sheet open={isSheetMenuOpen} onOpenChange={setIsSheetMenuOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" className="visible tablet:hidden" size="icon">
          <MenuIcon className="h-4 w-4" />
        </Button>
      </SheetTrigger>

      <SheetContent side="left">
        <SheetHeader>
          <SheetTitle>Menu</SheetTitle>
        </SheetHeader>

        <Links />
      </SheetContent>
    </Sheet>
  );
}
