/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-empty-function */
import jwt_decode from "jwt-decode";
import {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { showUserToken } from "@/services/user";
import { IUserShow } from "@/services/users/interfaces";
import { setStorageData, clearToken, getStorageData } from "@/utils";

interface AuthContextProps {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
  user: IUserShow | null;
  getUserToken: () => void;
  companyId: string | undefined | null;
  companyName: string | undefined | null;
  companyRegionId: string | undefined | null;
  companyIsBeta: boolean;
  companyNeighborhoodId: string | null;
  isAuthenticated: () => boolean;
  isMaster: () => boolean;
  isDeliveryman: () => boolean;
  mockCompanyId: string | null;
  setMockCompanyId: (id: string | null) => void;
  mockCompanyRegionId: string | null;
  mockCompanyIsBeta: boolean;
  setMockCompanyIsBeta: (isBeta: boolean) => void;
  setMockCompanyRegionId: (id: string | null) => void;
  isCompanyMaster: () => boolean;
  mockCompanyNeighborhoodId: string | null;
  setMockCompanyNeighborhoodId: (id: string | null) => void;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
);

const jwtSchema = z.object({
  exp: z.number(),
});

export const TOKEN_KEY = "zvcbcve";

export const AuthProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(null);
  const [userToken, setUserToken] = useState<IUserShow | null>(null);

  const getUserToken = useCallback(async () => {
    if (!userToken) {
      const token = getStorageData(TOKEN_KEY);

      if (!token) {
        return;
      }

      const decoded: any = jwt_decode(String(token));

      const currentUser = await showUserToken({
        id: decoded.sub,
        storedToken: String(token),
      });

      if (currentUser.status === 200) {
        setUserToken(currentUser?.data);
      }
    }
  }, [userToken]);

  const login = async (newToken: string) => {
    setStorageData(TOKEN_KEY, newToken);
    const decoded: any = jwt_decode(newToken);

    const currentUser = await showUserToken({
      id: decoded.sub,
      storedToken: newToken,
    });

    if (currentUser.status === 200) {
      setUserToken(currentUser?.data);
    }

    setToken(newToken);
  };

  const isAuthenticated = () => {
    try {
      const token = getStorageData(TOKEN_KEY);

      if (typeof token === "string" && token) {
        const decodedToken = jwt_decode(token);
        const result = jwtSchema.parse(decodedToken);

        if (result.exp * 1000 > Date.now()) {
          return true;
        }
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  const isMaster = () => {
    return userToken?.UserRoles?.some((role) => role.roleID === 1) || false;
  };

  const isDeliveryman = () => {
    return userToken?.UserRoles?.some((role) => role.roleID === 4) || false;
  };

  const isCompanyMaster = () => {
    return userToken?.UserRoles?.some((role) => role.roleID === 2) || false;
  };

  const logout = () => {
    setToken(null);
    clearToken();
    navigate("/");
  };

  const [companyIsBeta, setCompanyIsBeta] = useState<boolean>(false);
  const [companyNeighborhoodId, setCompanyNeighborhoodId] = useState<
    string | null
  >(null);
  const [companyRegionId, setCompanyRegionId] = useState<
    string | undefined | null
  >(undefined);
  const [companyName, setCompanyName] = useState<string | undefined | null>(
    undefined,
  );
  const [companyId, setCompanyId] = useState<string | undefined | null>(
    undefined,
  );

  useEffect(() => {
    if (!userToken) {
      getUserToken();
      return;
    }

    setCompanyIsBeta(userToken.companyIsBeta);
    setCompanyNeighborhoodId(userToken.companyNeighborhoodID);
    setCompanyRegionId(userToken.companyRegionID);
    setCompanyName(userToken.companyName);
    setCompanyId(userToken.companyID);
  }, [userToken]);

  useEffect(() => {
    if (isDeliveryman()) {
      toast.error("Entregadores não podem visualizar o dashboard", {
        id: "error-deliveryman",
      });
      logout();
    }
  }, [isDeliveryman]);

  const [mockCompanyId, setMockCompanyId] = useState<string | null>(null);
  const [mockCompanyRegionId, setMockCompanyRegionId] = useState<string | null>(
    null,
  );
  const [mockCompanyNeighborhoodId, setMockCompanyNeighborhoodId] = useState<
    string | null
  >(null);
  const [mockCompanyIsBeta, setMockCompanyIsBeta] = useState<boolean>(false);

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        user: userToken,
        getUserToken,
        companyId,
        companyName,
        companyRegionId,
        isAuthenticated,
        isMaster,
        isDeliveryman,
        mockCompanyId,
        setMockCompanyId,
        mockCompanyRegionId,
        setMockCompanyRegionId,
        mockCompanyNeighborhoodId,
        setMockCompanyNeighborhoodId,
        mockCompanyIsBeta,
        setMockCompanyIsBeta,
        isCompanyMaster,
        companyIsBeta,
        companyNeighborhoodId,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
