import { DotLottiePlayer } from "@dotlottie/react-player";
import { useState } from "react";

import { useInterval } from "@/hooks/use-interval";

export function AnimatedLoading() {
  const [dots, setDots] = useState(0);

  useInterval(() => {
    setDots((dots) => (dots + 1) % 4);
  }, 400);

  return (
    <div className="relative flex flex-col items-center justify-center p-4 text-center text-xs font-semibold">
      <DotLottiePlayer autoplay src="/loading.lottie" loop className="h-28" />
      <div className="absolute bottom-3">
        Carregando
        {".".repeat(dots)}
      </div>
    </div>
  );
}
