import { create } from "zustand";

type Command = {
  shortcut?: string;
  handler: () => void;
  label: string;
  icon: React.ReactNode;
};

export type UseCommandCenterState = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  toggleOpen: () => void;

  pageCommands: Command[];
  addPageCommand: (commands: Command) => void;
  clearPageCommands: () => void;
};

export const useCommandCenter = create<UseCommandCenterState>((set) => ({
  isOpen: false,
  setOpen: (isOpen) => set({ isOpen }),
  toggleOpen: () => set((state) => ({ isOpen: !state.isOpen })),

  pageCommands: [],
  addPageCommand: (pageCommand) =>
    set((state) => ({
      pageCommands: [...state.pageCommands, pageCommand],
    })),
  clearPageCommands: () => set({ pageCommands: [] }),
}));
