import { ReactNode } from "react";

export const FlexContainer = (props: {
  justify?: string;
  align?: string;
  direction?: string;
  style?: string;
  children: ReactNode | string;
}) => {
  return (
    <div
      className={`flex justify-${props.justify} items-${props.align} flex-${props.direction} ${props.style}`}
    >
      {props.children}
    </div>
  );
};
