import api from "@/services/api";
import { getStorageData } from "@/utils";

import { IPlansResp, IPlan, IPlanCreateResp } from "./interfaces";

const token = getStorageData("zvcbcve") || null;
const headers = token
  ? { Authorization: `Bearer ${getStorageData("zvcbcve")}` }
  : {};

export const getPlans = (params: {
  itemsPerPage?: number;
  currentPage?: number;
  all?: boolean;
}): Promise<IPlansResp> => {
  const resp: IPlansResp = {
    resp: null,
    status: null,
  };

  return api
    .get("plan", { params, headers })
    .then((preResp) => {
      resp.resp = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.resp = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const createPlan = (body?: IPlan | null): Promise<IPlanCreateResp> => {
  const resp: IPlanCreateResp = {
    data: null,
    status: null,
  };

  return api
    .post("plan", body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const updatePlans = (
  body: IPlan | null,
  id?: string,
): Promise<IPlanCreateResp> => {
  const resp: IPlanCreateResp = {
    data: null,
    status: null,
  };

  return api
    .put(`plan/${id}`, body)
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};
