import api from "@/services/api";
import { getStorageData } from "@/utils";

import { IDeliverymanResp } from "./interfaces";

const token = getStorageData("zvcbcve") || null;
const headers = token
  ? { Authorization: `Bearer ${getStorageData("zvcbcve")}` }
  : {};

export const getDeliveryMen = (params: {
  itemsPerPage?: number;
  page?: number;
  all?: boolean;
}): Promise<IDeliverymanResp> => {
  const resp: IDeliverymanResp = {
    resp: null,
    status: null,
  };

  return api
    .get("deliveryman", { params, headers })
    .then((preResp) => {
      resp.resp = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.resp = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

/* export const showMerchants = (props: { id?: string }): Promise<IMerchantShowResp> => {
  const resp: IMerchantShowResp = {
    data: null,
    status: null,
  };

  return api
    .get(`company/${props?.id}`)
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const createMerchant = (body: ICreateMerchant | null): Promise<IMerchantCreateResp> => {
  const resp: IMerchantCreateResp = {
    data: null,
    status: null,
  };

  return api
    .post('company', body)
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const updateMerchant = (
  body: ICreateMerchant | null,
  id?: string,
): Promise<IMerchantCreateResp> => {
  const resp: IMerchantCreateResp = {
    data: null,
    status: null,
  };

  return api
    .put(`company/${id}`, body)
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    })
    .finally(() => {
      return resp;
    });
};
 */
