export const usersCredencial = {
  MASTER: 1,
  COMPANY_MASTER: 2,
  COMPANY_WORKER: 3,
  DELIVERYMAN: 4,
};

export enum DeliveryStatusEnum {
  REGISTERED_DELIVERY = 1, // cadastrado
  WAITING_PACKAGE = 2, // aguardando pacote
  THROUGHOUT_DELIVERY = 3, // em rota entrega
  DELIVERED = 4, // entregue
  CANCELED_ESTABLISHMENT = 5, // cancelado pelo estabelecimento
  CANCELED_CLIENT = 6, // cancelado pelo cliente
  RETURNED = 7, // devolvido
  CANCELED_BY_ADMIN = 8, // cancelado pelo admin
}
