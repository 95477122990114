import axios from "axios";

import { IUserShowResp } from "@/services/users/interfaces";

const { VITE_API_URL } = import.meta.env;

export const showUserToken = (props: {
  id?: string;
  storedToken?: string;
  roles?: number[];
}): Promise<IUserShowResp> => {
  const resp: IUserShowResp = {
    data: null,
    status: null,
  };

  return axios
    .get(`${VITE_API_URL}/user/${props?.id}`, {
      headers: {
        Authorization: `Bearer ${props.storedToken}`,
      },
    })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};
