/* eslint-disable react/display-name */
import React, {
  ChangeEvent,
  useState,
  useRef,
  ForwardedRef,
  useImperativeHandle,
} from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

interface OutlinedInputProps {
  label?: string;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: string;
  boxStyle?: string;
  labelStyle?: string;
  name?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  min?: number;
  step?: string;
}

interface InputRef {
  focus(): void;
}

export const Input = React.forwardRef(
  (props: OutlinedInputProps, ref: ForwardedRef<InputRef>) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [showPassword, setShowPassword] = useState(false);

    const renderType = () => {
      if (props.type === "password" && showPassword) {
        return "text";
      }

      return props.type;
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
    }));

    return (
      <div className={`relative flex flex-col ${props.boxStyle}`}>
        <label
          className={`text-xs font-medium text-gray-600 ${props.labelStyle}`}
        >
          {props.label}
        </label>
        <input
          required={props.required}
          type={renderType() || "text"}
          value={props.value}
          name={props.name}
          step={props.step}
          min={props.min}
          ref={inputRef}
          disabled={props.disabled}
          onChange={props.onChange}
          placeholder={props.placeholder}
          className={`rounded-lg border-2 border-gray-300 px-4 py-2 text-gray-900 outline-none focus:ring-1 focus:ring-gray-300 dark:text-black ${props.style}`}
        />
        {props.type === "password" &&
          (showPassword ? (
            <button type="button">
              <AiFillEyeInvisible
                onClick={() => setShowPassword(false)}
                className="absolute right-3 top-[38px] -translate-y-2/4 transform text-2xl text-gray-500 hover:cursor-pointer"
              />
            </button>
          ) : (
            <button type="button">
              <AiFillEye
                onClick={() => setShowPassword(true)}
                className="absolute right-3 top-[38px] -translate-y-2/4 transform text-2xl text-gray-500 hover:cursor-pointer"
              />
            </button>
          ))}
      </div>
    );
  },
);
