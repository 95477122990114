import api from "@/services/api";
import { getStorageData } from "@/utils";

import {
  IRegionResp,
  IRegion,
  IRegionCreateResp,
  IAddNeighborhoodToRegionResp,
  IAddNeighborhoodToRegion,
  IAddPriceToNeighborhood,
  IUpdatePriceNeighborhood,
  IUpdatePriceToNeighborhoodResp,
  IAddPriceToNeighborhoodResp,
  IDeleteResp,
} from "./interfaces";

const token = getStorageData("zvcbcve") || null;
const headers = token
  ? { Authorization: `Bearer ${getStorageData("zvcbcve")}` }
  : {};

export const deleteRegion = (id?: string): Promise<IDeleteResp> => {
  const resp: IDeleteResp = {
    status: null,
  };

  return api
    .delete(`region/${id}`, { headers })
    .then((preResp) => {
      resp.status = preResp.status;

      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.status = err.response.status;
      }

      return resp;
    });
};

export const getRegion = (params: {
  itemsPerPage?: number;
  page?: number;
  all?: boolean;
}): Promise<IRegionResp> => {
  const resp: IRegionResp = {
    resp: null,
    status: null,
  };

  return api
    .get("region", { params, headers })
    .then((preResp) => {
      resp.resp = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.resp = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const createRegion = (
  body: Pick<IRegion, "regionName"> | null,
): Promise<IRegionCreateResp> => {
  const resp: IRegionCreateResp = {
    data: null,
    status: null,
  };

  return api
    .post("region", body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const addNeighborhoodToRegion = (
  body: IAddNeighborhoodToRegion | null,
): Promise<IAddNeighborhoodToRegionResp> => {
  const resp: IAddNeighborhoodToRegionResp = {
    data: null,
    status: null,
  };

  return api
    .post("region/addNeighborhoodToRegion", body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const addPriceToRegion = (
  body: IAddPriceToNeighborhood | null,
  id?: string,
): Promise<IAddPriceToNeighborhoodResp> => {
  const resp: IAddPriceToNeighborhoodResp = {
    data: null,
    status: null,
  };

  return api
    .post(`region/${id}/addPriceRegionToNeighborhood`, body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const updatePriceToRegion = (
  body: IUpdatePriceNeighborhood | null,
  id?: string,
): Promise<IUpdatePriceToNeighborhoodResp> => {
  const resp: IUpdatePriceToNeighborhoodResp = {
    data: null,
    status: null,
  };

  return api
    .put(`region/updatePriceRegionToNeighborhood/${id}`, body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const updateNeighborhood = (
  body: Pick<IRegion, "regionName"> | null,
  id?: string,
): Promise<IRegionCreateResp> => {
  const resp: IRegionCreateResp = {
    data: null,
    status: null,
  };

  return api
    .put(`region/${id}`, body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};
