import { CheckIcon, Loader2Icon, TriangleAlert } from "lucide-react";
import { Toaster as Sonner } from "sonner";

import { useTheme } from "@/components/theme-provider";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      icons={{
        success: <CheckIcon className="h-4 w-4 text-green-500" />,
        warning: <TriangleAlert className="h-4 w-4 text-yellow-500" />,
        loading: (
          <Loader2Icon className="h-4 w-4 animate-spin text-yellow-500" />
        ),
      }}
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
          success: "!text-green-500",
          warning: "!text-yellow-500",
          error: "!text-red-500",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
