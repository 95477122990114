import api from "@/services/api";
import { getStorageData } from "@/utils";

import {
  IUserResp,
  IUserShow,
  IUserCreateResp,
  IUserShowResp,
} from "./interfaces";

const token = getStorageData("zvcbcve") || null;
const headers = token
  ? { Authorization: `Bearer ${getStorageData("zvcbcve")}` }
  : {};

export const getUsers = (params: {
  itemsPerPage?: number;
  page?: number;
}): Promise<IUserResp> => {
  const resp: IUserResp = {
    resp: null,
    status: null,
  };

  return api
    .get("user", { params, headers })
    .then((preResp) => {
      resp.resp = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.resp = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const showUser = (props: { id?: string }): Promise<IUserShowResp> => {
  const resp: IUserShowResp = {
    data: null,
    status: null,
  };

  return api
    .get(`user/${props?.id}`, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const createUser = (
  body: Partial<IUserShow> | null,
): Promise<IUserCreateResp> => {
  const resp: IUserCreateResp = {
    data: null,
    status: null,
  };

  return api
    .post("user", body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const updateUser = (
  body: Partial<IUserShow> | null,
  id?: string,
): Promise<IUserCreateResp> => {
  const resp: IUserCreateResp = {
    data: null,
    status: null,
  };

  return api
    .put(`user/${id}`, body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};
