import api from "@/services/api";
import { getStorageData } from "@/utils";

import {
  IClientResp,
  IUpdateClientResp,
  IClientUpdate,
  IShowClientResp,
  IDeleteResp,
} from "./interfaces";

const token = getStorageData("zvcbcve") || null;
const headers = token
  ? { Authorization: `Bearer ${getStorageData("zvcbcve")}` }
  : {};

export const getClients = (params: {
  itemsPerPage?: number;
  cel?: string;
  all?: boolean;
  currentPage?: number;
}): Promise<IClientResp> => {
  const resp: IClientResp = {
    resp: null,
    status: null,
  };

  return api
    .get("client", { params, headers })
    .then((preResp) => {
      resp.resp = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.resp = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const showClient = (props: {
  id?: string;
}): Promise<IShowClientResp> => {
  const resp: IShowClientResp = {
    data: null,
    status: null,
  };

  return api
    .get(`client/${props?.id}`, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const updateClient = (
  body: Partial<IClientUpdate> | null,
  id?: string,
): Promise<IUpdateClientResp> => {
  const resp: IUpdateClientResp = {
    data: null,
    status: null,
  };

  return api
    .put(`client/${id}`, body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const deleteClient = (id?: string): Promise<IDeleteResp> => {
  const resp: IDeleteResp = {
    status: null,
  };

  return api
    .delete(`client/${id}`, { headers })
    .then((preResp) => {
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.status = err.response.status;
      }
      return resp;
    });
};

export const createClient = (
  body?: Partial<IClientUpdate> | null,
): Promise<IUpdateClientResp> => {
  const resp: IUpdateClientResp = {
    data: null,
    status: null,
  };

  return api
    .post("client", body, { headers })
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
};

/* export const createPlan = (body?: IPlan | null): Promise<IPlanCreateResp> => {
  const resp: IPlanCreateResp = {
    data: null,
    status: null,
  };

  return api
    .post('plan', body)
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
}; */

/* export const updateUser = (
  body: Partial<IUserShow> | null,
  id?: string,
): Promise<IUserCreateResp> => {
  const resp: IUserCreateResp = {
    data: null,
    status: null,
  };

  return api
    .put(`user/${id}`, body)
    .then((preResp) => {
      resp.data = preResp.data;
      resp.status = preResp.status;
      return resp;
    })
    .catch((err: any) => {
      if (err.response) {
        resp.data = err.response.data;
        resp.status = err.response.status;
      }
      return resp;
    });
}; */
