import { ErrorBoundary } from "@sentry/react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ErrorView } from "@/pages/error";

import App from "./app";
import "./index.css";

window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag("triggeredErrorBoundary", true);
    }}
    fallback={ErrorView}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>,
);
